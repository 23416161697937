import React from "react";

interface Props {
    delay?: number;
}

export default function Spinner(props: Props): JSX.Element | null {
    const delay = props.delay || 300;
    const [ready, setReady] = React.useState(false);

    React.useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        if (!ready) {
            timeout = setTimeout(() => setReady(true), delay);
        }
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    });

    if (!ready) return null;

    return (
        <div className="position-absolute top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>)
}
