import Head from 'next/head'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../styles/Layout.module.scss'

interface Props {
    children: React.ReactNode;
}

export default function NoAuthPage(props: Props): JSX.Element {
    const { children } = props;

    return <div className={styles.wrapper}>
            <Head>
                <title>OSAIA Care</title>
                <meta name="description" content="OSAIA Care" />
                <link rel="icon" href="/favicon.svg" />
            </Head>

            <main className={styles.main}>
                <div className="container-xxl px-0 flex-grow-1 d-flex flex-column">

                    {children}

                    <footer className={styles.footer}>
                        All rights reserved © 2024&nbsp;<a href="https://osaia.health" target="_blank" rel="noopener noreferrer">OSAIA Health ApS</a>
                    </footer>
                </div>
            </main>
            <ToastContainer theme="dark" />
        </div>
}
