import {signIn, signOut, useSession} from 'next-auth/react'
import {useEffect, useRef, useState} from "react";
import {useChat} from "./ChatContext";
import {formatDistanceToNow} from "date-fns";

interface Props {

}

export default function Header(props: Props): JSX.Element {
    const { data: session } = useSession()
    const [showDropdown, setShowDropdown] = useState(false);
    const [totalUnread, setTotalUnread] = useState(0);
    const { headerChatMessages } = useChat();

    const dropdownRef = useRef(null);

    useEffect(() => {
        if (headerChatMessages) {
            setTotalUnread(headerChatMessages.reduce((acc, user) =>
                    acc + (!user.read ? 1 : 0),
                0
            ));
        }
        // console.log("totalUnread => " + totalUnread)
    }, [headerChatMessages]);


    useEffect(() => {
        function handleClickOutside(event: any) {
            // @ts-ignore
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <header>
            <div className="container">
                <div className="d-flex justify-content-end align-items-center">
                    {session && (
                        <>
                            <div className="position-relative" ref={dropdownRef}>
                                <button className="btn"
                                        onClick={() => setShowDropdown(!showDropdown)}
                                        title="Chat"
                                >
                                    {totalUnread > 0 &&
                                        <span className="badge bg-danger position-absolute top-50 start-0 translate-middle">
                                            {totalUnread}
                                        </span>
                                    }
                                    <i className="fa fa-comments-o"></i>
                                </button>

                                {showDropdown &&
                                    <div className="dropdown-menu show position-absolute" style={{maxWidth: "300px"}}>
                                        {headerChatMessages.map(user => (
                                            <a key={user.userId} href={`/users/${user.userId}?tab=chat`} className="dropdown-item">
                                                {!user.read &&
                                                    <i className="fa fa-envelope me-2" style={{color: "red"}}></i> }
                                                {user.read &&
                                                    <i className="fa fa-envelope-open me-2"></i> }
                                                {user.name}<br/>
                                                <div style={{fontSize: ".8rem"}}>
                                                    {formatDistanceToNow(new Date(user.timestamp), { addSuffix: true })}
                                                </div>
                                                <small style={{
                                                    display: "block",
                                                    color: "darkgray",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap"
                                                }}>
                                                    {user.text}
                                                </small>
                                            </a>
                                        ))}
                                    </div>}
                            </div>
                            <span>{session.user?.email ?? "N/A"}</span>
                            <button onClick={() => signOut()} className="btn btn-secondary ms-2">Log ud</button>
                        </>
                    )}
                    {!session && (
                        <>
                            <button onClick={() => signIn()} className="btn btn-secondary ms-2">Log ind</button>
                        </>
                    )}
                </div>
            </div>
        </header>
    )
}
