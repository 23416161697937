import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/v4-shims.min.css'
import 'react-tooltip/dist/react-tooltip.css'
import "react-datepicker/dist/react-datepicker.css";

import '../styles/globals.scss'
import type {AppProps} from 'next/app'
import {SessionProvider} from "next-auth/react"
import {NextPage} from 'next'
import Head from 'next/head'
import {QueryClient, QueryClientProvider} from 'react-query';
import {Session} from 'next-auth';
import Page from '../components/Page';
import NextNProgress from 'nextjs-progressbar';
import {useRouter} from "next/router";
import NoAuthPage from "../components/NoAuthPage";
import {ChatProvider} from "../components/ChatContext";


if (typeof window === 'undefined') {
  process.env.TZ = "UTC";
  const logger = require('../serverHelpers/logger').default;
  logger.info('App started');
}

export type NextApplicationPage<P = any, IP = P> = NextPage<P, IP> & {
  requireAuth?: boolean
}

const queryClient = new QueryClient()

export default function App({
  Component,
  pageProps
}: AppProps<{
  session: Session;
}>) {
  const router = useRouter()
  // console.log("router path => ", router.pathname)
  //console.log("Component => ", Component)
  const needsAuth = !router.pathname.startsWith("/reset-pass") &&
      !router.pathname.startsWith("/account-delete") &&
      !router.pathname.endsWith("/changelog-pdf")

  return <>
    <Head>
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" key="viewport" />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="description" content="" key="description" />
    </Head>
    <SessionProvider session={pageProps.session}>
      <QueryClientProvider client={queryClient}>
        {needsAuth ? (
            <ChatProvider>
              <Page>
                <Component {...pageProps} />
                <NextNProgress color="#0d6efd" />
              </Page>
            </ChatProvider>
        ) : (
            <NoAuthPage>
              <Component {...pageProps} />
              <NextNProgress color="#0d6efd" />
            </NoAuthPage>
        )}
      </QueryClientProvider>
    </SessionProvider>
  </>
}
