import classNames from "classnames"
import {useSession} from "next-auth/react"
import Link from "next/link"
import {useRouter} from "next/router"
import Image from "next/legacy/image"
import styles from '../styles/Layout.module.scss'
import {Role} from "../types/enums"
import {isPatient} from "../helpers/auth";

const userPages = [
  { text: "Start", route: "/" },
  { text: "Indhold", route: "/articles" },
  { text: "Profil", route: "/profile" },
  // { text: "Politik", route: "/policy" },
]
const advisorPages = [
  { text: "Start", route: "/" },
  { text: "Brugere", route: "/users" },
  { text: "Chat", route: "/chat" },
  { text: "Indhold", route: "/articles" },
  { text: "Profil", route: "/profile" },
  { text: "Advice", route: "/advice" },
]

const adminPages = [
  { text: "Advisors", route: "/advisors" }
]

export function Navigation() {
  const router = useRouter()
  const { data: session } = useSession()
  let links: { route: string; text: string }[];
    if (!isPatient(session?.user?.role)) {
        links = session?.user.role == Role.Admin ? advisorPages.concat(adminPages) : advisorPages;
    } else {
        links = userPages;
    }

  return (
    <div className={classNames(styles.sidebar, "flex-column flex-shrink-0 bg-dark text-white p-3")}>
      <Link
        href="/"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none px-3 pb-1">

        {/* <span className="ms-2 mt-2 h3 mb-0 fw-bold">Ekorai</span> */}
        <Image src={"/logo_white_vertical.svg"} alt="OSAIA" width="1467" height="685" />

      </Link>

      <ul className="nav nav-pills flex-column mt-3 mb-auto">
        {links.map((link) => (
          <li key={link.route} className="nav-item">
            <Link
              href={link.route}
              className={classNames("nav-link text-white",
                {
                  ["active"]: router.route == link.route || (link.route != "/" && router.route.includes(link.route))
                })}>
              {link.text}
            </Link>
          </li>
        )
        )}
      </ul>
    </div>
  );
}
