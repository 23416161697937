import {ContentCategory} from "@prisma/client";

export enum Role {
    Admin = "ADMIN",
    Advisor = "ADVISOR",
    Patient = "PATIENT",
}


export enum ArticleCategory {
    Osteoporose = "OSTEOPOROSE",
    Training = "TRAINING",
    Kost = "KOST",
    Medicin = "MEDICIN",
    Livskvalitet = "LIVSKVALITET",
    Motivation = "MOTIVATION",
}

export const ArticleCategoryLabel: { [key in ArticleCategory]: string } = {
    [ArticleCategory.Osteoporose]: "Osteoporose-mestring",
    [ArticleCategory.Training]: "Træning",
    [ArticleCategory.Kost]: "Kost",
    [ArticleCategory.Medicin]: "Medicin",
    [ArticleCategory.Livskvalitet]: "Livskvalitet",
    [ArticleCategory.Motivation]: "Motivation"
};


export const ArticleCategoryId: { [key in ArticleCategory]: number } = {
    [ArticleCategory.Osteoporose]: 1115,
    [ArticleCategory.Training]: 1116,
    [ArticleCategory.Kost]: 1113,
    [ArticleCategory.Medicin]: 1111,
    [ArticleCategory.Livskvalitet]: 1117,
    [ArticleCategory.Motivation]: 1128
};

export const ArticleCategoryFromId: { [key in number]: ArticleCategory } = {
    [1115]: ArticleCategory.Osteoporose,
    [1116]: ArticleCategory.Training,
    [1113]: ArticleCategory.Kost,
    [1111]: ArticleCategory.Medicin,
    [1117]: ArticleCategory.Livskvalitet,
    [1128]: ArticleCategory.Motivation
};

export const ContentCategoryPrisma: { [key in ArticleCategory]: ContentCategory } = {
    [ArticleCategory.Osteoporose]: ContentCategory.OSTEOPOROSE,
    [ArticleCategory.Training]: ContentCategory.TRAINING,
    [ArticleCategory.Kost]: ContentCategory.KOST,
    [ArticleCategory.Medicin]: ContentCategory.MEDICIN,
    [ArticleCategory.Livskvalitet]: ContentCategory.LIVSKVALITET,
    [ArticleCategory.Motivation]: ContentCategory.MOTIVATION
};

export const ArticleCategoryFromPrisma: { [key in ContentCategory]: ArticleCategory } = {
    [ContentCategory.OSTEOPOROSE]: ArticleCategory.Osteoporose,
    [ContentCategory.TRAINING]: ArticleCategory.Training,
    [ContentCategory.KOST]: ArticleCategory.Kost,
    [ContentCategory.MEDICIN]: ArticleCategory.Medicin,
    [ContentCategory.LIVSKVALITET]: ArticleCategory.Livskvalitet,
    [ContentCategory.MOTIVATION]: ArticleCategory.Motivation
};


/* Usage

let selectedCategory: ArticleCategory = ArticleCategory.Kost;

console.log(selectedCategory);              // Outputs: "KOST" (the enum's underlying value)
console.log(ArticleCategoryId[selectedCategory]); // Outputs: 3

 */
