import React, {createContext, useContext, useEffect, useState} from 'react';
import io from 'socket.io-client';
import {useSession} from "next-auth/react";
import {ChatUserData, HeaderChatMessage} from "../types/chat";


type ChatContextType = {
    socket: any;
    headerChatMessages: HeaderChatMessage[];
    setChatUserData: (chatUserData: ChatUserData | null) => void;
    chatUserData: ChatUserData | null;
    currentChatPatientId: string | null;
    setCurrentChatPatientId: (id: string | null) => void;
};

const ChatContext = createContext<ChatContextType | undefined>(undefined);


export const useChat = () => {
    const context = useContext(ChatContext);
    if (!context) {
        throw new Error('useNotifications must be used within a NotificationsProvider');
    }
    return context;
};

type ChatProviderProps = {
    children: React.ReactNode;
};


export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<any>(null);
    const [headerChatMessages, setHeaderChatMessages] = useState<HeaderChatMessage[]>([]);
    // const [messages, setMessages] = useState<any[]>([]);
    const [currentChatPatientId, setCurrentChatPatientId] = useState<string | null>(null);
    const [chatUserData, setChatUserData] = useState<ChatUserData | null>(null);
    const { data: session } = useSession();

    useEffect(() => {
        const fetchData = async () => {
            await fetch('/api/advisor/socket')
        }

        const result = fetchData().catch(console.error);

        // @ts-ignore
        const socketInstance = io({
            path: '/api/socket.io/',
            auth: {
                token: session,
                advisorId: session?.user?.id
            }
        });


        socketInstance.on('connect', () => {
            setSocket(socketInstance);
            console.log('Connected to server');
        });

        socketInstance.on('connect_error', (error: any) => {
            console.error('Connection error:', JSON.stringify(error));
        });


        socketInstance.on('disconnect', () => {
            console.log('Disconnected from server');
        })

        socketInstance.on("chatMessages", (message: HeaderChatMessage[]) => {
            console.log("client chat messages => ", JSON.stringify(message))
            setHeaderChatMessages(message);
        });

        socketInstance.on('chatUserData', async (updatedData: ChatUserData) => {
            console.log("chatUserData" + JSON.stringify(updatedData))
            setChatUserData(updatedData);
        });

        return () => {
            socketInstance.disconnect();
        };
    }, [session]);

    return (
        <ChatContext.Provider value={{ socket, headerChatMessages, currentChatPatientId, setCurrentChatPatientId, chatUserData, setChatUserData }}>
            {children}
        </ChatContext.Provider>
    );
};
